import * as React from 'react';
interface DappIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const DappIcon = React.forwardRef<HTMLImageElement, DappIconProps>(({
  src = '/dAppIcon.svg',
  alt = 'Dapp Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} // This points to public/dappIcon.svg
  alt={alt} {...props} />;
});
const ForwardRef = DappIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<DappIconProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;