import { defineChain } from 'viem';

export const krnlDevnetLocalnet = defineChain({
  id: 14123,
  name: 'KRNL Devnet Localnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['http://localhost:8545'],
    },
  },
});

export const krnlDevnetInternal = defineChain({
  id: 14122,
  name: 'KRNL Devnet Internal',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://devnet-internal.node.lat:8545'],
    },
  },
});

export const krnlDevnet = defineChain({
  id: 14121,
  name: 'KRNL Devnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://devnet.node.lat:8545'],
    },
  },
});
