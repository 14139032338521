'use client';

import { SidebarLayout } from '@/components/custom/sidebar-layout-modified';
import { NavbarLabel } from '@/components/navbar';
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection, SidebarSpacer } from '@/components/sidebar';
import { Text } from '@/components/text';
import { HomeIcon, PuzzlePieceIcon, QuestionMarkCircleIcon, SparklesIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import DappIcon from '@/icons/DappIcon';
import KernelIcon from '@/icons/KernelIcon';
import ContractIcon from '@/icons/Contract';
import Loading from './loading';
import { DocumentTextIcon } from '@heroicons/react/16/solid';
const navItems = [{
  label: 'Home',
  url: '/',
  icon: <HomeIcon className="focus:text-shark-400" />
}, {
  label: 'Kernels',
  url: '/kernel',
  icon: <KernelIcon className="focus:text-shark-400" />
}, {
  label: 'Smart contracts',
  url: '/contract',
  icon: <ContractIcon className="focus:text-shark-400" />
}, {
  label: 'dApps',
  url: '/dapp',
  icon: <DappIcon className="focus:text-shark-400" />
}, {
  label: 'Explore',
  url: '/explore',
  icon: <PuzzlePieceIcon className="active:fill-red-400" />
}];
export function PlatformLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);
  return <SidebarLayout sidebar={<Sidebar>
          <SidebarHeader className="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-2xl bg-bluekrnl-100 py-4 pl-4 pr-9 lg:mb-2.5">
            <div className="flex cursor-pointer items-center gap-2">
              <div className="relative h-[48px] w-[48px] text-shark-400 lg:h-[48x] lg:w-[48px]">
                <Image src="/krnl.svg" alt="Platform" fill priority sizes="(max-width: 1024px) 32px, 32px" quality={50} />
              </div>
              <NavbarLabel className="flex flex-row items-baseline gap-x-2 truncate leading-none">
                <Text className="text-sm font-semibold !text-white lg:text-xl">
                  Platform
                </Text>
              </NavbarLabel>
              <button disabled className="light:text-white hidden rounded-lg border-[1px] border-[#3D3D3D] bg-backgrounddetail px-[6px] py-[4px] text-[12px] text-white dark:text-white lg:flex">
                Beta
              </button>
              {/* <Button className="hidden text-[12px] lg:flex px-6 py-4" disabled>Beta</Button> */}
            </div>
          </SidebarHeader>
          <SidebarBody className="mt-24">
            <SidebarSection>
              {navItems.map(({
          label,
          url,
          icon
        }) => <SidebarItem key={label} href={url} current={pathname === url} className="hover:shadow-shark-950">
                  {icon}
                  <SidebarLabel current={pathname === url}>
                    {label}
                  </SidebarLabel>
                </SidebarItem>)}
            </SidebarSection>

            <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href="https://discord.com/invite/byc84wU7SG" target="_blank">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="https://docs.platform.lat" target="_blank">
                <SparklesIcon />
                <SidebarLabel>Documentation</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/registry">
                <DocumentTextIcon />
                <SidebarLabel>Registry Details</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
        </Sidebar>} data-sentry-element="SidebarLayout" data-sentry-component="PlatformLayout" data-sentry-source-file="platform-layout.tsx">
      {hydrated ? children : <Loading />}
    </SidebarLayout>;
}