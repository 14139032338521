'use client';

import { ReactNode } from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { State, WagmiProvider } from 'wagmi';
import { config, projectId } from '@/config';

// // In case Create our own WalletConnect provider
// export const config = createConfig({
//   chains: [sepolia],
//   transports: {
//     [sepolia.id]: http(),
//   },
//   connectors: [
//     injected(),
//     walletConnect({ projectId, metadata, showQrModal: false }),
//     metaMask(),
//     safe(),
//   ],
//   ssr: true,
//   storage: createStorage({
//     storage: cookieStorage,
//   }),
// });

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  featuredWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96']
});
const queryClient = new QueryClient();
export default function Web3ModalProvider({
  children,
  initialState
}: {
  children: ReactNode;
  initialState?: State;
}) {
  return <WagmiProvider config={config} initialState={initialState} data-sentry-element="WagmiProvider" data-sentry-component="Web3ModalProvider" data-sentry-source-file="Web3Modal.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="Web3Modal.tsx">{children}</QueryClientProvider>
    </WagmiProvider>;
}