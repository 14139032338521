import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { cookieStorage, createStorage } from 'wagmi';
import { sepolia } from 'wagmi/chains';
import { krnlDevnet, krnlDevnetInternal, krnlDevnetLocalnet } from './network';

// 2. Load your project ID
export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID || '';

// 3. Create a metadata object
const metadata = {
  name: process.env.NEXT_PUBLIC_APP_NAME || '',
  description: process.env.NEXT_PUBLIC_APP_DESCRIPTION || '',
  url: process.env.NEXT_PUBLIC_APP_URL || '',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};
declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

//Fee Feature Flag
export const featureFlag = {
  isFeeActive: process.env.NEXT_PUBLIC_FEATURE_IS_FEE_ACTIVE !== 'false'
};
const chainMapping: {
  [key: string]: any;
} = {
  sepolia,
  devnet: krnlDevnet,
  'devnet-internal': krnlDevnetInternal,
  'devnet-localnet': krnlDevnetLocalnet
};
export const chains = [chainMapping[process.env.NEXT_PUBLIC_NETWORK_NAME as string] || sepolia] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage
  }),
  auth: {
    socials: [],
    email: false,
    showWallets: true,
    walletFeatures: true
  }
});