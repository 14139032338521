import { NavbarItem } from '../navbar';
import ConnectWalletButton from './connectWallet';
type DynamicTopRendererProps = {
  OpenMenuIcon: React.JSX.Element;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};
const DynamicTopRenderer: React.FC<DynamicTopRendererProps> = ({
  OpenMenuIcon,
  setShowSidebar
}) => {
  return <header data-sentry-component="DynamicTopRenderer" data-sentry-source-file="dynamic-top-renderer.tsx">
      <div className="flex h-full items-center px-4">
        <div className="fixed left-4 top-2 z-20">
          <div className="lg:hidden">
            <NavbarItem className="flex rounded-lg bg-shark-950" onClick={() => setShowSidebar(true)} aria-label="Open navigation" data-sentry-element="NavbarItem" data-sentry-source-file="dynamic-top-renderer.tsx">
              {OpenMenuIcon}
            </NavbarItem>
          </div>
        </div>
        <div className="fixed right-4 top-4 z-20">
          <div className="min-w-0 flex-1">
            <div className="bg flex flex-row-reverse justify-between">
              <div className="z-10 ml-auto flex items-center space-x-4 lg:ml-0">
                <ConnectWalletButton data-sentry-element="ConnectWalletButton" data-sentry-source-file="dynamic-top-renderer.tsx" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>;
};
export default DynamicTopRenderer;