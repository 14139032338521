import * as React from 'react';
interface kernelIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const KernelIcon = React.forwardRef<HTMLImageElement, kernelIconProps>(({
  src = '/kernelIcon.svg',
  alt = 'dApp Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = KernelIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<kernelIconProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;