'use client';

import { Button } from '@/components/button';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import React, { useEffect, useState } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { CheckIcon, ChevronDownIcon, GlobeAltIcon } from '@heroicons/react/16/solid';
import { Listbox, ListboxOption } from '../listbox';
import { availableNetworks } from '@/model/network';
import { Tooltip, TooltipContent, TooltipTrigger } from '../custom/tooltipv2';
function LoadingButton() {
  return <Button disabled className="flex cursor-not-allowed items-center justify-center gap-2 rounded-lg bg-gray-600 px-4 py-2 text-white" data-sentry-element="Button" data-sentry-component="LoadingButton" data-sentry-source-file="connectWallet.tsx">
      <svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="connectWallet.tsx">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="connectWallet.tsx"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z" data-sentry-element="path" data-sentry-source-file="connectWallet.tsx"></path>
      </svg>
      Loading...
    </Button>;
}
const ConnectWalletButton: React.FC = () => {
  const {
    open
  } = useWeb3Modal();
  const {
    isConnected,
    address
  } = useAccount();
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    return <LoadingButton />;
  }
  function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength / 2) + '...' + text.slice(-maxLength / 2);
  }
  return <>
      {!isConnected ? <Button className="cursor-pointer" color="white" onClick={() => open()}>
          Connect Wallet
        </Button> : <Button onClick={() => open()} color="shark_with_border" className="">
          {address && truncateText(address, 8)}
          <span>
            <ChevronDownIcon className="h-4 w-4" />
          </span>
        </Button>}
    </>;
};
export default ConnectWalletButton;