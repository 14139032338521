'use client';

import * as Headless from '@headlessui/react';
import React, { useState } from 'react';
import DynamicTopRenderer from '../complete/dynamic-top-renderer';
import { NavbarItem } from '../navbar';
function OpenMenuIcon() {
  return <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" data-sentry-element="svg" data-sentry-component="OpenMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx">
      <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z" data-sentry-element="path" data-sentry-source-file="sidebar-layout-modified.tsx" />
    </svg>;
}
function CloseMenuIcon() {
  return <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" data-sentry-element="svg" data-sentry-component="CloseMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" data-sentry-element="path" data-sentry-source-file="sidebar-layout-modified.tsx" />
    </svg>;
}
function MobileSidebar({
  open,
  close,
  children
}: React.PropsWithChildren<{
  open: boolean;
  close: () => void;
}>) {
  return <Headless.Dialog open={open} onClose={close} className="lg:hidden" data-sentry-element="unknown" data-sentry-component="MobileSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
      <Headless.DialogBackdrop transition className="fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx" />
      <Headless.DialogPanel transition className="fixed inset-y-0 z-30 w-full max-w-80 transition duration-300 ease-in-out data-[closed]:-translate-x-full" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx">
        <div className="flex h-full flex-col rounded-lg bg-white shadow-sm ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <div className="-mb-3 px-4 pt-3">
            <Headless.CloseButton as={NavbarItem} aria-label="Close navigation" data-sentry-element="unknown" data-sentry-source-file="sidebar-layout-modified.tsx">
              <CloseMenuIcon data-sentry-element="CloseMenuIcon" data-sentry-source-file="sidebar-layout-modified.tsx" />
            </Headless.CloseButton>
          </div>
          {children}
        </div>
      </Headless.DialogPanel>
    </Headless.Dialog>;
}
export function SidebarLayout({
  sidebar,
  children
}: React.PropsWithChildren<{
  sidebar: React.ReactNode;
}>) {
  let [showSidebar, setShowSidebar] = useState(false);
  return <div className="flex h-full min-h-screen" data-sentry-component="SidebarLayout" data-sentry-source-file="sidebar-layout-modified.tsx">
      {/* Sidebar on desktop */}
      <div className="fixed inset-y-0 left-0 top-0 z-20 w-[280px] border-r border-borderTertiary bg-backgroundtable p-4 shadow-[0px_0px_8px_0px_#FFFFFF0D_inset] max-lg:hidden">
        {sidebar}
      </div>

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)} data-sentry-element="MobileSidebar" data-sentry-source-file="sidebar-layout-modified.tsx">
        {sidebar}
      </MobileSidebar>

      {/* Content */}
      <div className="flex h-full min-h-screen flex-1 flex-col bg-black lg:ml-[278px]">
        <DynamicTopRenderer OpenMenuIcon={<OpenMenuIcon />} setShowSidebar={setShowSidebar} data-sentry-element="DynamicTopRenderer" data-sentry-source-file="sidebar-layout-modified.tsx" />
        <main className="flex-1 rounded-lg bg-black ring-1 ring-shark-950/5">
          {children}
        </main>
      </div>
    </div>;
}