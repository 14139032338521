import * as React from 'react';
interface ContractProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
}
const ContractIcon = React.forwardRef<HTMLImageElement, ContractProps>(({
  src = '/contractIcon.svg',
  alt = 'contract Icon',
  ...props
}, imgRef) => {
  return <img ref={imgRef} src={src} alt={alt} {...props} />;
});
const ForwardRef = ContractIcon as React.ForwardRefExoticComponent<React.PropsWithoutRef<ContractProps> & React.RefAttributes<HTMLImageElement>>;
export default ForwardRef;